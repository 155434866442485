/**
 *
 * LoginPage
 *
 */

import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoginOutlined } from '@ant-design/icons';

import img from './logo_ESERO_blanco.png';
import {useDataState} from "../../config/State";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        span: 24,
    },
};

const MainWrapper = styled.div`
    height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackGround = styled.div`
  background: url(${img}) no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100px;
`;

const Container = styled.div`
  max-width: 450px;
  width: 100%;
  border: darkgrey solid 1px;
  margin: 10px;
  border-radius: 12px;
  box-shadow: 0 7px 25px -3px rgba(0, 0, 0, 0.8);
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  padding: 0 40px 40px;
  background: #142c42;
`;

const Header = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  color: white;
  background: rgba(169, 169, 169, 0.59);
  font-weight: bold;
`;

const FormWrapper = styled.div`
  padding-top: 30px;
  
  label {
    color: white !important;
  }
`;

function LoginPage() {
    const {signIn, email, loading, errorMessage} = useDataState();
    const [form] = Form.useForm();

    const onFinish = values => {
        signIn(values.username, values.password);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (errorMessage) {
            form.setFields([
                {
                    name: 'username',
                    errors: [''],
                },
                {
                    name: 'password',
                    errors: ['Usuario y/o contraseña incorrectos'],
                },
            ]);
        }
    }, [errorMessage, form]);

    if (email) {
        return <Navigate to="/" />;
    }

    return (
        <MainWrapper>
            <div
                className="container"
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
                <Container>
                    <Header>Panel Cliente</Header>
                    <Content>
                        <BackGround />
                        <FormWrapper>
                            <Form
                                {...layout}
                                form={form}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    label="E-mail"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor, introduce un nombre de usuario',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Contraseña"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor, introduce una contraseña',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            float: 'right',
                                            backgroundColor: '#6495ED',
                                            borderColor: '#6495ED',
                                        }}
                                        type="primary"
                                        htmlType="submit"
                                        loading={!!loading}
                                        icon={<LoginOutlined />}
                                        size="large"
                                        shape="round"
                                    >
                                        Iniciar sesión
                                    </Button>
                                </Form.Item>
                            </Form>
                        </FormWrapper>
                    </Content>
                </Container>
            </div>
        </MainWrapper>
    );
}

export default LoginPage
