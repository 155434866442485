/**
 *
 * Collage
 *
 */

import React from 'react';
import { Table } from 'antd';

import Commafy from '../../utils/Commafy';
import {useDataState} from "../../config/State";
import {MainContainer, Points, SchoolName} from "../../utils/globalStyles";

export function Collage() {
    const {activeSchools, data = activeSchools} = useDataState()
    const columns = [
        {
            title: 'Nombre del colegio',
            dataIndex: 'schoolName',
            key: 'schoolName',
            sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
            render: (text, { place }) => (
                <SchoolName>
                    <span>{text}</span>
                    <span>{place}</span>
                </SchoolName>
            ),
        },
        {
            title: 'Alumnos',
            dataIndex: 'alumns',
            key: 'alumns',
            sorter: (a, b) => a.alumns - b.alumns,
            render: students => <Points>{Commafy(students)}</Points>,
            align: 'center',
            className: 'vertical-middle',
        },
        {
            title: 'Puntos',
            dataIndex: 'totalPoints',
            key: 'totalPoints',
            render: point => <Points>{Commafy(point)}</Points>,
            sorter: (a, b) => a.totalPoints - b.totalPoints,
            align: 'center',
            className: 'vertical-middle',
        },
        {
            title: 'Partidas jugadas',
            dataIndex: 'totalPlayed',
            key: 'totalPlayed',
            render: played => <Points>{Commafy(played)}</Points>,
            sorter: (a, b) => a.totalPlayed - b.totalPlayed,
            align: 'center',
            className: 'vertical-middle',
        },
    ];

    return (
        <MainContainer>
            <Table columns={columns} dataSource={data} size="small" />
        </MainContainer>
    );
}

export default Collage
