import styled from 'styled-components';
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";

import PageLayout from "./components/PageLayout";
import ErrorPage from "./containers/ErrorPage";
import LoginPage from "./containers/LoginPage";
import General from "./containers/General";
import Collage from "./containers/Collage";
import PrivateRoute from "./utils/PrivateRoute";
import {StateProvider} from "./config/State";

function App() {
  return (
      <StateProvider>
          <AppWrapper>
              <BrowserRouter>
                  <PageLayout>
                      <Routes>
                          <Route path={'/login'} element={<LoginPage />} />
                          <Route element={<PrivateRoute />}>
                              <Route path={'/'} element={<General />} />
                              <Route path={'/general'} element={<General />} />
                              <Route path={'/collage'} element={<Collage />} />
                          </Route>
                          <Route path="*" exact component={ErrorPage} />
                      </Routes>
                  </PageLayout>
              </BrowserRouter>
          </AppWrapper>
      </StateProvider>
  );
}

export default App;

const AppWrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
`;
