/**
 *
 * PageLayout
 *
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import './layout.css';

import SideMenu from './SideMenu';
import Loading from '../../components/Loading';
import {useDataState} from "../../config/State";

const {Content, Header} = Layout;

export function PageLayout({children}) {
    const {email, logout, loading, activeSchools} = useDataState()
    const [collapsed, setCollapsed] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    if (!email) {
        return <>{children}</>;
    }

    return (
        <Layout style={{height: '100vh'}}>
            <Loading loading={loading}/>
            <SideMenu collapsed={collapsed} logout={logout}/>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{padding: 0}}>
                    <Content className="c-main-bar">
                        <span>
                          {React.createElement(
                              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                              {
                                  className: 'trigger',
                                  onClick: toggle,
                              },
                          )}
                            PANEL MANAGER
                        </span>
                        <div>
                            <span style={{padding: '0 24px'}}>Total Colegios Activos: <span style={{fontWeight: 'bold', color: '#0047AB'}}>{activeSchools.length}</span></span>
                        </div>
                    </Content>
                </Header>
                <div
                    style={{
                        position: 'relative',
                        overflow: loading ? 'hidden' : 'scroll',
                    }}
                >
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '16px 16px',
                            padding: 0,
                            minHeight: 280,
                            position: 'relative',
                            backgroundColor: 'transparent',
                        }}
                    >
                        {children}
                    </Content>
                </div>
            </Layout>
        </Layout>
    );
}

PageLayout.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    email: PropTypes.string,
    password: PropTypes.string,
    children: PropTypes.any,
    logout: PropTypes.func,
    loadingStats: PropTypes.bool,
};


export default PageLayout;
