import React, {useContext, useEffect, useReducer} from "react";
import axios from "axios";
import {API_URL} from "./NetworkConfig";

const defaultState = {
    fetching: false,
    error: null,
    email: null,
    password: null,
    errorMessage: '',
    totalNumGames: 0,
    totalGamesMonth: 0,
    totalGamesToday: 0,
    totalPlayed: 0,
    playedMonth: 0,
    playedToday: 0,
    totalTeachers: 0,
    teachersMonth: 0,
    teachersToday: 0,
    loggedTeachers: 0,
    totalStudents: 0,
    studentsMonth: 0,
    studentsToday: 0,
    loggedStudents: 0,
    totalMissions: 0,
    missionsMonth: 0,
    missionsToday: 0,
    activeSchools: [],
    students: [],
}

export const StateContext = React.createContext(defaultState);

const Actions = {
    LOG_IN: 'LOG_IN',
    LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
    LOG_IN_FAILURE: 'LOG_IN_FAILURE',
    LOG_OUT: 'LOG_OUT',
    LOG_OUT_SUCCESS: 'LOG_OUT_SUCCESS',
    LOG_OUT_FAILURE: 'LOG_OUT_FAILURE'
}

const reduce = (state, action) => {
    switch (action.type) {
        case Actions.LOG_IN:
            return { ...state, logged: false, email: null, password: null, loading: true }
        case Actions.LOG_IN_SUCCESS:
            return { loading: false, errorMessage: null, logged: true, ...action.payload }
        case Actions.LOG_IN_FAILURE:
            return { loading: false, errorMessage: 'LOG_IN_FAILURE', logged: false, email: null, password: null }
        case Actions.LOG_OUT:
            return { ...state, loading: true }
        case Actions.LOG_OUT_SUCCESS:
            return defaultState
        case Actions.LOG_OUT_FAILURE:
            return { ...state, loading: false, errorMessage: 'LOG_OUT_FAILURE' }
        default:
            return state
    }
}

export const useDataState = () => useContext(StateContext)

export const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer(reduce, defaultState)

    const signIn = async (user, pass) => {
        dispatch({ type: Actions.LOG_IN })
        try {
            const res = await axios.post(API_URL, {user, pass})
            const {stats, errno} = res.data
            if (errno === 0) {
                const {
                    total_juegos_creados,
                    juegos_creados_mes,
                    juegos_creados_hoy,
                    total_partidas,
                    partidas_mes,
                    partidas_hoy,
                    usuarios_registrados_total,
                    usuarios_registrados_mes,
                    usuarios_registrados_hoy,
                    usuarios_logged_hoy,
                    usuarios_teacher_registrados_total,
                    usuarios_teacher_registrados_mes,
                    usuarios_teacher_registrados_hoy,
                    usuarios_teacher_logged_hoy,
                    activeSchools,
                } = stats;
                dispatch({
                    type: Actions.LOG_IN_SUCCESS,
                    payload: {
                        email: user,
                        password: pass,
                        totalNumGames: total_juegos_creados,
                        totalGamesMonth: juegos_creados_mes,
                        totalGamesToday: juegos_creados_hoy,
                        totalPlayed: total_partidas,
                        playedMonth: partidas_mes,
                        playedToday: partidas_hoy,
                        totalTeachers: usuarios_teacher_registrados_total,
                        teachersMonth: usuarios_teacher_registrados_mes,
                        teachersToday: usuarios_teacher_registrados_hoy,
                        loggedTeachers: usuarios_teacher_logged_hoy,
                        totalStudents: usuarios_registrados_total - usuarios_teacher_registrados_total,
                        studentsMonth: usuarios_registrados_mes - usuarios_teacher_registrados_mes,
                        studentsToday: usuarios_registrados_hoy - usuarios_teacher_registrados_hoy,
                        loggedStudents: usuarios_logged_hoy - usuarios_teacher_logged_hoy,
                        activeSchools: activeSchools.map((item, key) => ({
                            key,
                            ...item,
                            totalPlayed: item.stats.total_partidas,
                            totalPoints: item.stats.total_puntos,
                        })),
                    }
                })
                localStorage.setItem('ESERO_USER_STATE', JSON.stringify({ user, pass }));
            } else {
                dispatch({ type: Actions.LOG_IN_FAILURE })
                localStorage.removeItem('ESERO_USER_STATE');
            }
        } catch (e) {
            console.log(e)
            console.log('Request Error!')
            dispatch({ type: Actions.LOG_IN_FAILURE })
            localStorage.removeItem('ESERO_USER_STATE');
        }

    }

    const logout = async () => {
        dispatch({ type: Actions.LOG_OUT })
        try {
            localStorage.removeItem('ESERO_USER_STATE');
            dispatch({ type: Actions.LOG_OUT_SUCCESS })
        } catch (error) {
            console.log(error)
            dispatch({ type: Actions.LOG_OUT_FAILURE })
        }
    }

    useEffect( () => {
        const localState = localStorage.getItem('ESERO_USER_STATE');
        if (localState) {
            const {user, pass} = JSON.parse(localState)
            signIn(user, pass)
                .then(() => console.log('re-logged'))
                .catch(() => {
                    logout().then(() => console.log('logged out'))
                })
        }
    }, []);

    return (
        <StateContext.Provider
            value={{
                ...state,
                signIn,
                logout
            }}
        >
            {children}
        </StateContext.Provider>
    );
};