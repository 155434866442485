/**
 *
 * Page404
 *
 */

import React from 'react';
import styled from 'styled-components';

import img from './image.png';

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > h1 {
    width: 100%;
    font-size: 50px;
    text-align: center;
  }

  img {
    filter: grayscale(100%);
  }
`;

export function ErrorPage() {
    return (
        <MainContainer>
            <h1>404</h1>
            <img src={img} alt="sad" />
            <p>¡Vaya! No hemos encontrado la página que buscas.</p>
        </MainContainer>
    );
}

export default ErrorPage
