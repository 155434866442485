/**
 *
 * Loading
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const SpinnerCon = styled.div`
  position: fixed;
  display: flex;
  z-index: 10;
  background-color: rgba(192, 192, 192, 0.4);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

function Loading({ loading = false, style = {} }) {
  if (!loading) {
    return null;
  }

  return (
    <SpinnerCon style={style}>
      <Spin size="large" />
    </SpinnerCon>
  );
}

Loading.propTypes = {
  loading: PropTypes.bool,
  style: PropTypes.object,
};

export default Loading;
