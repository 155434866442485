import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import {
  PoweroffOutlined,
  SlidersOutlined,
  BankOutlined
} from '@ant-design/icons';

import Logo from './logo_ESERO_blanco.png';
import LogoSmall from './boton-volver@3x.png';
const { Sider } = Layout;

const getPath = () => {
  const initialPath = window.location.pathname.split('class');

  if (initialPath && initialPath.length > 1) {
    const parts = initialPath[1].split('/');

    if (parts.length > 2) {
      return [`/class/${parts[1]}`, `/class/${parts[1]}/${parts[2]}`];
    }

    return [`/class/${parts[1]}`];
  }

  return [];
};

const SideMenu = ({ logout = () => {}, collapsed = false }) => {
  const [path, setPath] = useState(window.location.pathname);
  const [openKeys, setOpenKeys] = React.useState([]);

  const onOpenChange = keys => {
    if (collapsed) {
      setOpenKeys(keys);
      return;
    }
    const paths = getPath();
    if (paths.length) {
      // eslint-disable-next-line no-shadow
      const hasNew = paths.find((path, i) => openKeys[i] !== path);
      if (!hasNew) {
        paths.pop();
      }
      setOpenKeys(paths);
    } else {
      setOpenKeys(keys);
    }
  };

  useEffect(() => {
    if (collapsed) {
      setOpenKeys([]);
    } else {
      setOpenKeys(getPath());
    }
  }, [collapsed]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <Link to="/">
        <div className="logo">
          <img
            className="logo-img-layout"
            src={!collapsed ? Logo : LogoSmall}
            alt="academons"
          />
        </div>
      </Link>
      <Menu
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={[
          {
            key: '1',
            icon: <SlidersOutlined />,
            label: <Link to="/" onClick={() => setPath('/')}>General</Link>,
            className: (path === '/' || path === '/general') && 'cus-menu-item-li'
          },
          {
            key: '2',
            icon: <BankOutlined />,
            label: <Link to="/collage" onClick={() => setPath('/collage')}>Colegios activos</Link>,
            className: path === '/collage' && 'cus-menu-item-li'
          },
        ]}
      />
      <div className="sider-panel-footer">
        <Button type="text" danger icon={<PoweroffOutlined />} onClick={logout}>
          {!collapsed && 'Cerrar sesión'}
        </Button>
      </div>
    </Sider>
  );
};

SideMenu.propTypes = {
  logout: PropTypes.func,
  collapsed: PropTypes.bool,
};

export default SideMenu;
