/**
 *
 * General
 *
 */

import React from 'react';
import styled from 'styled-components';
import {useDataState} from "../../config/State";

export function General() {
    const {
        totalPlayed,
        playedMonth,
        playedToday,
        totalTeachers,
        teachersMonth,
        teachersToday,
        loggedTeachers,
        totalStudents,
        studentsMonth,
        studentsToday,
        loggedStudents,
    } = useDataState()

    return (
        <MainContainer>
            <RowWrapper>
                <Title>Datos de uso: misiones</Title>
                <Row>
                    <Column>
                        <p>
                            Partidas jugadas <br /> TOTAL
                        </p>
                        <p>{totalPlayed}</p>
                    </Column>
                    <Column>
                        <p>
                            Partidas jugadas <br /> MES
                        </p>
                        <p>{playedMonth}</p>
                    </Column>
                    <Column>
                        <p>
                            Partidas jugadas <br /> HOY
                        </p>
                        <p>{playedToday}</p>
                    </Column>
                </Row>
            </RowWrapper>

            <RowWrapper>
                <Title>Datos de uso: docentes</Title>
                <Row>
                    <Column>
                        <p>
                            Docentes registrados <br /> TOTAL
                        </p>
                        <p>{totalTeachers}</p>
                    </Column>
                    <Column>
                        <p>
                            Docentes registrados <br /> MES
                        </p>
                        <p>{teachersMonth}</p>
                    </Column>
                    <Column>
                        <p>
                            Docentes registrados <br /> HOY
                        </p>
                        <p>{teachersToday}</p>
                    </Column>
                    <Column>
                        <p>
                            Docentes activos <br /> HOY
                        </p>
                        <p>{loggedTeachers}</p>
                    </Column>
                </Row>
            </RowWrapper>

            <RowWrapper>
                <Title>Datos de uso: alumnado</Title>
                <Row>
                    <Column>
                        <p>
                            Alumnos registrados <br /> TOTAL
                        </p>
                        <p>{totalStudents}</p>
                    </Column>
                    <Column>
                        <p>
                            Alumnos registrados <br /> MES
                        </p>
                        <p>{studentsMonth}</p>
                    </Column>
                    <Column>
                        <p>
                            Alumnos registrados <br /> HOY
                        </p>
                        <p>{studentsToday}</p>
                    </Column>
                    <Column>
                        <p>
                            Alumnos activos <br /> HOY
                        </p>
                        <p>{loggedStudents}</p>
                    </Column>
                </Row>
            </RowWrapper>
        </MainContainer>
    );
}

const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background: white;
  flex: 1;
  margin: 10px 0;
  border-radius: 10px;
  max-height: 250px;
`;

const Title = styled.div`
  font-family: 'Days One', sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  display: flex;
  color: #333333;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > p {
    text-align: center;
    font-size: 16px;

    &:last-child {
      font-size: 28px;
      font-weight: bold;
      color: #005555;
      font-family: 'Days One', sans-serif;
    }
  }
`;

export default General
