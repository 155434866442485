import styled from "styled-components";
import {Tag} from "antd";

export const Points = styled.span`
  display: flex;
  flex-direction: column;
  color: #0047AB;
  font-size: 16px;
  font-weight: bolder;
  font-family: 'Days One', sans-serif;
`;

export const SchoolName = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    &:last-child {
      font-size: 11px;
      color: #727272;
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  background: white;
  overflow: scroll;
  padding: 0 10px;
`;

export const Tags = styled(Tag)`
  margin: 0;
`;
