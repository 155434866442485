import React from 'react';
import {  Navigate, Outlet } from 'react-router-dom';
import {useDataState} from "../config/State";

function PrivateRoute() {
    const {email} = useDataState()
    const localState = localStorage.getItem('ESERO_USER_STATE');
    return email || localState ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoute